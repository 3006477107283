<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Add New Partnership
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
    <validation-observer ref="addCategoryFormalidate" v-slot="{ validate }">
      <b-form ref="form" class="mt-2" style="width: 100%" @submit.prevent="handleSubmit(validate)">
        <b-row>
          <b-col cols="12" v-for="(field, index) in addCategoryForm.fields" :key="index" class="border rounded p-2 mb-2">
            <b-row>
              <b-col md="4">
                <b-form-group label="Select Category" class="mb-2">
                  <validation-provider rules="required" v-slot="{ errors }" name="Category">
                    <v-select
                      v-model="field.category_id"
                      :options="categoriesList"
                       label="type"
                      :reduce="val => val.id"
                      placeholder="Select a category"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="6">
                <b-media no-body vertical-align="center" class="flex-column flex-md-row">
                  <div class="d-inline-block">
                    <b-media-aside>
                      <b-img
                        :src="field.imagePreview"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                    </b-media-aside>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      class="rounded ml-1 mt-2 text-center"
                      @click="removeImage(index)"
                    >
                      Remove Image
                    </b-button>
                  </div>
                  <b-media-body>
                    <b-card-text class="mt-1" />
                    <div class="d-inline-block mt-1">
                      <b-form-group label="Upload Image" class="mb-2">
                        <validation-provider rules="required" v-slot="{ errors }" name="Image">
                          <b-form-file
                            v-model="field.image"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @change="e => inputImageRenderer(e, index)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Name">
                  <validation-provider rules="required" v-slot="{ errors }" name="Name">
                    <b-form-input v-model="field.name" placeholder="Name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Link" class="mt-2">
                  <validation-provider rules="url" v-slot="{ errors }" name="Link">
                    <b-form-input v-model="field.link" placeholder="Link" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col cols="12" class="text-right">
                <b-button size="sm" variant="danger" class="mx-1" @click="removeField(index)" v-if="index != 0">-</b-button>
                <b-button size="sm" variant="primary" @click="addField" v-if="addCategoryForm.fields.length-1 == index && index!=3" :disabled="addCategoryForm.fields.length >= 4">+</b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
            <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

            <b-button
              v-if="isLoading"
              variant="primary"
              disabled
              class="mr-1"
            >
              Loading...
              <b-spinner small />
              <span class="sr-only" />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref, reactive } from '@vue/composition-api'
import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import vSelect from 'vue-select'

export default {
  setup(props, { emit }) {
    const defaultImage = 'media/svg/files/blank-image.svg'
    const addCategoryFormalidate = ref()
    const addCategoryForm = reactive({
      category: '',
      fields: [
        { image: null, imagePreview: defaultImage, altText: '', name: '', link: '' }
      ],
      categories: ['Category 1', 'Category 2', 'Category 3'] // Replace with actual categories
    })

    const addField = () => {
      if (addCategoryForm.fields.length < 4) {
        addCategoryForm.fields.push({ image: null, imagePreview: defaultImage, altText: '', name: '', link: '' })
      }
    }

    const removeField = (index) => {
      addCategoryForm.fields.splice(index, 1)
    }

    const removeImage = (index) => {
      addCategoryForm.fields[index].image = null
      addCategoryForm.fields[index].imagePreview = defaultImage
    }

    const inputImageRenderer = async (event, index) => {
      const token = localStorage.getItem('token')
  isLoading.value = true
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = async (e) => {
          const formData = new FormData()
          formData.append('image', file)
          
          try {
            const response = await axios.post(`${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`, formData, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })
    
              isLoading.value = false
            addCategoryForm.fields[index].imagePreview = response.data.location // Assuming the API response contains the URL in `location`
          } catch (error) {
            console.error('Image upload failed:', error)
            // Handle error appropriately
          }
        }
        reader.readAsBinaryString(file)
      }
    }

    const validateAndSave = async (validate) => {
      const isValid = await validate()
      if (isValid) {
        save()
      }
    }
    const isLoading = ref(false)
    const save = () => {
      // Your save logic here
        
    
      let myArray = addCategoryForm.fields.map(el => ({
        "name": el.name,
        "image": el.imagePreview,
        "link": el.link,
        "category_id": el.category_id
      }))

      let result = { "sponsors": myArray }

      addCategoryFormalidate.value.validate().then(success => {
        if (success) {
           isLoading.value = true
          store.dispatch('categories/addsponsors', result)
            .then(response => {
              addCategoryForm.fields=
                [ { image: null, imagePreview: defaultImage, altText: '', name: '', link: '' }]
                  emit('add-partnership')
                isLoading.value = false
              Vue.swal({
                text: ' Added ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(e=>{
               isLoading.value = false
            })
        }
      })
    }

    const categoriesList = ref([])
    store.dispatch('categories/AllCategory')
      .then(response => {
        categoriesList.value = response.data.data
      })

    return {
      addCategoryForm,
      isLoading,
      addCategoryFormalidate,
      addField,
      removeField,
      removeImage,
      categoriesList,
      inputImageRenderer,
      validateAndSave,
      save,
      defaultImage
    }
  },

  components: {
    BCard,
    BMedia,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BImg,
    BRow,
    vSelect,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    ValidationProvider,
    ValidationObserver
  },

  directives: {
    Ripple
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>

<style>
.mb-2.col-12 {
  margin: -1px !important;
}
.border.rounded.p-2 {
  margin: 8px 1px !important;
}
.col-12 {
  margin: 0px;
}
</style>
