<template>
  <b-card title="PartnerShip">

    <b-tabs>

      <b-tab>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <a href="">Add PartnerShip</a>
        </template>

        <AddPartnership @add-partnership="getData" />
      </b-tab>
      <b-tab @click="$router.push('/all-partnership')">
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/all-categories">All Categories</a> -->
          <span>All PartnerShip</span>
        </template>
        
        <List ref="allData"></List>
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import List from './list.vue'
import AddPartnership from './AddPartnership.vue'

export default {
  components: {
List,
    BCard,
    BTabs,
    BTab,

    AddPartnership,
  },
  setup() {
    const allData = ref()

    const getData = () => {

      allData.value.getaccreditations()

    }
    return {
      getData,
      allData,

    }
  },
}
</script>
